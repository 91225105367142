// swiperjs

// Initializing Swiper
const swiper = new Swiper('.section__specials--block', {
    slidesPerView: 2,
    initialSlide: 0,
    spaceBetween: 60,
    loop: false,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        },
    },
})

const swiper2 = new Swiper('.section__bxa--block', {
    effect: "fade",
    slidesPerView: 1,
    // spaceBetween: 100,
    centeredSlides: true,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    // breakpoints: {
    //     100: {
    //         slidesPerView: 1,
    //     },
    //     1024: {
    //         slidesPerView: 2,
    //     },
    //     1200: {
    //         slidesPerView: 3,
    //     },
    // },
})
// Upscaling low-res background images after page load
const containersToUpscaleMed = document.querySelectorAll('[data-medres]');
const containersToUpscaleHigh = document.querySelectorAll('[data-hires]');

// Medium Screen Size
if (window.innerWidth >= 960 && window.innerWidth <= 1920 && containersToUpscaleMed.length > 0) {
    containersToUpscaleMed.forEach((container) => {
        // Create a new image and set its path to hires path
        const newPath = container.dataset.medres;
        const medresImage = new Image();
        medresImage.src = newPath;

        // When image loads, replace bg image with new one
        // TODO: store loaded state in localStorage and check status earlier (in head perhaps)
        // And set hires from localStorage so user doesn't have to wait
        medresImage.addEventListener('load', () => {
            container.style.backgroundImage = `url(${newPath})`;
            medresImage.removeEventListener('load', () => {});
        });
    });
// High Resolution Screen Size
}else if(window.innerWidth > 1920 && containersToUpscaleHigh.length > 0){
    containersToUpscaleHigh.forEach((container) => {
        // Create a new image and set its path to hires path
        const newPath = container.dataset.hires;
        const hiresImage = new Image();
        hiresImage.src = newPath;

        // When image loads, replace bg image with new one
        // TODO: store loaded state in localStorage and check status earlier (in head perhaps)
        // And set hires from localStorage so user doesn't have to wait
        hiresImage.addEventListener('load', () => {
            container.style.backgroundImage = `url(${newPath})`;
            hiresImage.removeEventListener('load', () => {});
        });
    });
// Normal Screen options
}else if(window.innerWidth >= 960 && containersToUpscaleHigh > 0){
    containersToUpscaleHigh.forEach((container) => {
        // Create a new image and set its path to hires path
        const newPath = container.dataset.hires;
        const hiresImage = new Image();
        hiresImage.src = newPath;

        // When image loads, replace bg image with new one
        // TODO: store loaded state in localStorage and check status earlier (in head perhaps)
        // And set hires from localStorage so user doesn't have to wait
        hiresImage.addEventListener('load', () => {
            container.style.backgroundImage = `url(${newPath})`;
            hiresImage.removeEventListener('load', () => {});
        });
    });
}